"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.index-of.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.parse-float.js");

require("core-js/modules/es.parse-int.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.search.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _usePrevious = _interopRequireDefault(require("react-use/lib/usePrevious"));

var _client = require("@apollo/client");

var _areEqual = _interopRequireDefault(require("fbjs/lib/areEqual"));

var _link = require("@meepshop/link");

var _AdTrack = _interopRequireDefault(require("@meepshop/context/lib/AdTrack"));

var _useProducts = require("../gqls/useProducts");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var SORT_FIELDS = ['createdAt-desc', 'title.zh_TW-asc', 'variantInfo.firstRetailPrice-asc', 'variantInfo.firstRetailPrice-desc'];

var useProducts = function useProducts(_ref) // controlled : 系統預設的商品列表元件（搜尋頁面)， uncontrolled：店家自行拖拉的商品列表元件
{
  var _router$query;

  var id = _ref.id,
      ids = _ref.ids,
      tags = _ref.tags,
      price = _ref.price,
      offset = _ref.offset,
      limit = _ref.limit,
      includedAllTags = _ref.includedAllTags,
      search = _ref.search,
      sort = _ref.sort;
  var productListType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'uncontrolled';
  var router = (0, _link.useRouter)();
  var size = parseInt(limit, 10) > 500 ? 500 : parseInt(limit, 10);
  var adTrack = (0, _react.useContext)(_AdTrack["default"]);
  var variables = (0, _react.useMemo)(function () {
    var _String$split = String(SORT_FIELDS.includes(sort) ? sort : SORT_FIELDS[0]).split('-'),
        _String$split2 = (0, _slicedToArray2["default"])(_String$split, 2),
        field = _String$split2[0],
        order = _String$split2[1];

    var output = {
      search: {
        size: size,
        // FIXME: custom sorting workaround
        from: sort === 'selections' ? 0 : parseInt(offset, 10),
        filter: {
          and: [{
            type: 'exact',
            field: 'status',
            query: '1'
          }].concat((0, _toConsumableArray2["default"])(productListType === 'controlled' ? // 搜尋頁面 - 只需取得 isDisplayOnSearchPage 為 true 的來顯示， 因為 isDisplayOnSearchPage 為 true 代表商品會顯示在所有頁面
          [{
            type: 'exact',
            field: 'displayToggle.isDisplayOnSearchPage',
            query: 'true'
          }] : [{
            type: 'exact',
            field: 'displayToggle.isDisplayOnProductPage',
            query: 'true'
          }])),
          or: []
        },
        sort: [{
          field: field,
          order: order
        }],
        showVariants: true,
        showMainFile: true
      }
    };

    if (typeof ids === 'string' && ids !== '') {
      // FIXME: custom sorting workaround
      if (sort === 'selections') output.search.filter.and.push({
        type: 'ids',
        ids: ids.split(',').slice(parseInt(offset, 10), parseInt(offset, 10) + size)
      });else output.search.filter.and.push({
        type: 'ids',
        ids: ids.split(',')
      });
    }

    if (typeof search === 'string' && search !== '') output.search.filter.or.push({
      type: 'query_string',
      child: 'variant',
      fields: ['sku', 'vendorSku'],
      query: search.replace(/~/g, '')
    }, {
      type: 'query_string',
      fields: ['title.zh_TW'],
      query: search.replace(/~/g, '')
    });

    if (typeof tags === 'string' && tags !== '') {
      var tagsFilter = tags.split(',').map(function (query) {
        return {
          type: 'exact',
          field: 'tags',
          query: query
        };
      });
      output.search.filter.and = output.search.filter.and.concat((includedAllTags === null || includedAllTags === void 0 ? void 0 : includedAllTags.toString()) === 'true' ? tagsFilter : [{
        or: tagsFilter
      }]);
    }

    if (typeof price === 'string' && price !== '') {
      var _price$split = price.split(','),
          _price$split2 = (0, _slicedToArray2["default"])(_price$split, 2),
          gte = _price$split2[0],
          lte = _price$split2[1];

      output.search.filter.and.push(_objectSpread(_objectSpread({
        type: 'range',
        child: 'variant',
        field: 'retailPrice'
      }, !gte ? {} : {
        gte: parseFloat(gte)
      }), !lte ? {} : {
        lte: parseFloat(lte)
      }));
    }

    return output;
  }, [sort, size, offset, ids, search, tags, price, includedAllTags, productListType]); // uniqueProductAndBlockId 解決 Product 頁面因複製商品造成 block id 一樣滑到 block id 的 hack，若非商品頁面則會是 -blockId

  var uniqueProductAndBlockId = "".concat((router === null || router === void 0 ? void 0 : (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.pId) || '', "-").concat(id);
  var prevUniqueProductAndBlockId = (0, _usePrevious["default"])(uniqueProductAndBlockId);
  var prevVariables = (0, _usePrevious["default"])(variables);

  var _useQuery = (0, _client.useQuery)(_useProducts.getProducts, {
    variables: variables,
    onCompleted: function onCompleted(newData) {
      var _newData$computeProdu;

      if (search && newData !== null && newData !== void 0 && (_newData$computeProdu = newData.computeProductList) !== null && _newData$computeProdu !== void 0 && _newData$computeProdu.data) adTrack.search({
        searchString: search,
        products: newData.computeProductList.data
      });
    }
  }),
      data = _useQuery.data,
      loading = _useQuery.loading;

  (0, _react.useEffect)(function () {
    if (prevVariables && !(0, _areEqual["default"])(prevVariables, variables) && // 商品頁相同時觸發，切換商品頁面時則不觸發 scroll
    (0, _areEqual["default"])(prevUniqueProductAndBlockId, uniqueProductAndBlockId)) {
      setTimeout(function () {
        var fixedTopMenu = document.getElementById('menu-fixedTop');
        var element = document.getElementById(id); // 預設預留與上方的 padding ，滑到此 dom 時才不會非常貼合

        var PADDING_OFFSET = 30;

        if (element) {
          window.scrollTo({
            top: element.offsetTop - ((fixedTopMenu === null || fixedTopMenu === void 0 ? void 0 : fixedTopMenu.offsetHeight) || 0) - PADDING_OFFSET,
            behavior: 'smooth'
          });
        }
      }, 500);
    }
  }, [id, prevUniqueProductAndBlockId, prevVariables, uniqueProductAndBlockId, variables]);
  return {
    data: (0, _react.useMemo)(function () {
      if (!data || !ids || sort !== 'selections') return data;
      var order = String(ids).split(','); // FIXME: custom sorting workaround

      return _objectSpread(_objectSpread({}, data), {}, {
        computeProductList: _objectSpread(_objectSpread({}, data.computeProductList), {}, {
          data: (0, _toConsumableArray2["default"])(data.computeProductList.data).sort(function (a, b) {
            return order.indexOf(a.id) - order.indexOf(b.id);
          })
        })
      });
    }, [data, ids, sort]),
    loading: loading
  };
};

var _default = useProducts;
exports["default"] = _default;