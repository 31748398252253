"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REMOVE_WISHLIST = exports.ADD_WISHLIST = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  mutation removeProductFromWishList($input: RemoveWishlistProductInput!) {\n    removeWishlistProduct(input: $input) {\n      success\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  mutation addProductLToWishList($input: AddWishlistProductInput!) {\n    addWishlistProduct(input: $input) {\n      success\n      wishlistProduct {\n        id\n        productId\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ADD_WISHLIST = (0, _client.gql)(_templateObject());
exports.ADD_WISHLIST = ADD_WISHLIST;
var REMOVE_WISHLIST = (0, _client.gql)(_templateObject2());
exports.REMOVE_WISHLIST = REMOVE_WISHLIST;