"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SORT_OPTIONS = exports.PRODUCT_TYPE = exports.VARIANT_TYPE = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _propTypes2 = require("../constants/propTypes");

var VARIANT_TYPE = _propTypes["default"].shape({
  id: _propTypes2.ID_TYPE.isRequired,
  listPrice: _propTypes["default"].number,
  suggestedPrice: _propTypes["default"].number,
  retailPrice: _propTypes["default"].number.isRequired,
  discountPrice: _propTypes["default"].number,
  totalPrice: _propTypes["default"].number.isRequired,
  sku: _propTypes["default"].string.isRequired
});

exports.VARIANT_TYPE = VARIANT_TYPE;

var PRODUCT_TYPE = _propTypes["default"].shape({
  id: _propTypes2.ID_TYPE.isRequired,
  specs: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    id: _propTypes2.ID_TYPE.isRequired,
    title: _propTypes2.LOCALE_TYPE
  }).isRequired),
  description: _propTypes2.LOCALE_TYPE.isRequired,
  status: _propTypes["default"].oneOf([0, 1]).isRequired,
  title: _propTypes2.LOCALE_TYPE.isRequired,
  variants: _propTypes["default"].arrayOf(VARIANT_TYPE.isRequired).isRequired,
  showUserPrice: _propTypes["default"].shape({
    showListPrice: _propTypes["default"].bool.isRequired,
    showSuggestedPrice: _propTypes["default"].bool.isRequired
  })
});

exports.PRODUCT_TYPE = PRODUCT_TYPE;

var SORT_OPTIONS = function SORT_OPTIONS(ids, value) {
  return [].concat((0, _toConsumableArray2["default"])(ids ? [{
    text: 'selections',
    value: 'selections'
  }] : []), (0, _toConsumableArray2["default"])(value === 'createdAt-asc' ? [{
    text: 'oldest',
    value: value
  }] : []), [{
    text: 'latest',
    value: 'createdAt-desc'
  }, {
    text: 'title',
    value: 'title.zh_TW-asc'
  }, {
    text: 'price-asc',
    value: 'variantInfo.firstRetailPrice-asc'
  }, {
    text: 'price-desc',
    value: 'variantInfo.firstRetailPrice-desc'
  }]);
};

exports.SORT_OPTIONS = SORT_OPTIONS;