"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.link = void 0;
var link = {
  display: 'inherit',
  color: 'inherit',
  textDecoration: 'none'
};
exports.link = link;