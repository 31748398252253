"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProductGalleries = exports.getProducts = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getProductGalleries($search: searchInputObjectType) {\n    computeProductList(search: $search) {\n      data {\n        id\n        galleries {\n          images {\n            id\n            imageExists\n            scaledSrc {\n              w480\n              w720\n              w960\n              w1200\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getProducts($search: searchInputObjectType) {\n    computeProductList(search: $search) {\n      data {\n        id\n        title {\n          ...localeFragment\n        }\n        description {\n          ...localeFragment\n        }\n        variants {\n          id\n          specs {\n            id\n            title {\n              ...localeFragment\n            }\n          }\n          stock\n          listPrice\n          suggestedPrice\n          totalPrice\n          sku\n        }\n        coverImage {\n          id\n          imageExists\n          scaledSrc {\n            w480\n            w720\n            w960\n            w1200\n          }\n        }\n        showUserPrice {\n          showListPrice\n          showSuggestedPrice\n        }\n      }\n      total\n    }\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var getProducts = (0, _client.gql)(_templateObject(), _locale.localeFragment);
exports.getProducts = getProducts;
var getProductGalleries = (0, _client.gql)(_templateObject2());
exports.getProductGalleries = getProductGalleries;