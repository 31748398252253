"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _client = require("@apollo/client");

var _GodsEye = require("@meepshop/context/lib/GodsEye");

var _AdTrack = _interopRequireDefault(require("@meepshop/context/lib/AdTrack"));

var _useWishList = require("../gqls/useWishList");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n              fragment updateWishListAddCacheFragment on User {\n                id\n                wishList: wishlist {\n                  id\n                }\n              }\n            "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n              fragment updateWishListAddCacheFragment on User {\n                id\n                wishList: wishlist {\n                  id\n                  productId\n                }\n              }\n            "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var useWishList = function useWishList(user) {
  var _useMutation = (0, _client.useMutation)(_useWishList.ADD_WISHLIST),
      _useMutation2 = (0, _slicedToArray2["default"])(_useMutation, 1),
      mutationAddWishItem = _useMutation2[0];

  var _useMutation3 = (0, _client.useMutation)(_useWishList.REMOVE_WISHLIST),
      _useMutation4 = (0, _slicedToArray2["default"])(_useMutation3, 1),
      mutationRemoveWishItem = _useMutation4[0];

  var adTrack = (0, _react.useContext)(_AdTrack["default"]);

  var _useGodsEyeContext = (0, _GodsEye.useGodsEyeContext)(),
      handleTrack = _useGodsEyeContext.handleTrack,
      trackProperties = _useGodsEyeContext.trackProperties;

  var addWishItem = (0, _react.useCallback)(function (product) {
    var productId = product === null || product === void 0 ? void 0 : product.id;
    if (!productId) return;
    mutationAddWishItem({
      variables: {
        input: {
          productId: productId
        }
      },
      update: function update(cache, _ref) {
        var _product$variants, _product$variants$, _product$variants2, _product$variants2$, _product$variants3, _product$variants3$;

        var addWishlistProduct = _ref.data.addWishlistProduct;
        if (!addWishlistProduct.success) return;
        cache.writeFragment({
          id: user.id,
          fragment: (0, _client.gql)(_templateObject()),
          data: {
            __typename: 'User',
            id: user.id,
            wishList: [].concat((0, _toConsumableArray2["default"])((user === null || user === void 0 ? void 0 : user.wishList) || []), [_objectSpread({}, addWishlistProduct.wishlistProduct)])
          }
        });
        handleTrack(trackProperties.clickAddToWishlist);
        adTrack.addToWishList({
          id: productId,
          title: (product === null || product === void 0 ? void 0 : product.title) || '',
          quantity: 1,
          specs: (product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : (_product$variants$ = _product$variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.specs) || null,
          price: (product === null || product === void 0 ? void 0 : (_product$variants2 = product.variants) === null || _product$variants2 === void 0 ? void 0 : (_product$variants2$ = _product$variants2[0]) === null || _product$variants2$ === void 0 ? void 0 : _product$variants2$.totalPrice) || 0,
          sku: (product === null || product === void 0 ? void 0 : (_product$variants3 = product.variants) === null || _product$variants3 === void 0 ? void 0 : (_product$variants3$ = _product$variants3[0]) === null || _product$variants3$ === void 0 ? void 0 : _product$variants3$.sku) || ''
        });
      }
    });
  }, [mutationAddWishItem, adTrack, user, handleTrack, trackProperties.clickAddToWishlist]);
  var removeWishItem = (0, _react.useCallback)(function (productId) {
    mutationRemoveWishItem({
      variables: {
        input: {
          productId: productId
        }
      },
      update: function update(cache, _ref2) {
        var removeWishlistProduct = _ref2.data.removeWishlistProduct;
        if (!removeWishlistProduct.success) return;
        cache.writeFragment({
          id: user.id,
          fragment: (0, _client.gql)(_templateObject2()),
          data: {
            __typename: 'User',
            id: user.id,
            wishList: ((user === null || user === void 0 ? void 0 : user.wishList) || []).filter(function (_ref3) {
              var wishListProductId = _ref3.productId;
              return wishListProductId !== productId;
            })
          }
        });
      }
    });
  }, [mutationRemoveWishItem, user]);
  return {
    addWishItem: addWishItem,
    removeWishItem: removeWishItem
  };
};

var _default = useWishList;
exports["default"] = _default;